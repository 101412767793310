import pagesConfiguration from '@app/pages/configuration.slice'
import dropboxSlice from '@app/pages/Integrations/Integtrations.slice'
import onboardingSlice from '@app/state/slices/onboarding.slice'
import { combineReducers, Reducer } from 'redux'

import { ReduxState } from '../initialState'
import accessPolicies from './accessPolicies'
import appRegistrations from './appRegistrations'
import archivistnode from './archivistnode'
import assets from './assets'
import blobs from './blobs'
import currentAssetEvents from './currentAssetEvents'
import events from './events'
import identityProviders from './identityProviders'
import locations from './locations'
import rootPrincipals from './rootPrincipals'
import sampleSets from './sampleSets'
import subjects from './subjects'
import users from './users'

const reducer: Reducer<ReduxState> = combineReducers({
  events,
  assets,
  currentAssetEvents,
  locations,
  archivistnode,
  identityProviders,
  rootPrincipals,
  subjects,
  sampleSets,
  appRegistrations,
  accessPolicies,
  blobs,
  users,
  onboarding: onboardingSlice,
  dropboxConnection: dropboxSlice,
  pagesConfiguration,
})

export default reducer
