import { Dispatch, SetStateAction } from 'react'

import { default as api } from '../api'
import { AssetsFilterParams } from '../api/resources/assets'
import { EventsFilterParams } from '../api/resources/events'
import { AlertData } from '../common/Alerts/Alerts.types'
import { RkvstAsset, RkvstCreateAsset, RkvstEvent, RkvstLocation } from './initialState'

export const apiAction = {
  /* Redux action states ('fulfilled' is not 'success' due to legacy code) */
  fulfilled: (action: string) => action + '_FULFILLED',
  pending: (action: string) => action + '_PENDING',
  failed: (action: string) => action + '_FAILED',
  rejected: (action: string) => action + '_REJECTED',
}

export default {
  events: {
    getList: (params?: any) => ({
      type: 'api/events/getList',
      payload: api.events.getList(params),
    }),
    getListFiltered: (params: EventsFilterParams, nextPageToken?: string) => ({
      type: 'api/events/getListFiltered',
      payload: api.events.getListFiltered(params, nextPageToken),
      meta: {
        nextPageToken,
      },
    }),
    setCurrentFilterConditions: (currentFilterConditions: Array<any>) => ({
      type: 'events/setCurrentFilterConditions',
      currentFilterConditions,
    }),
  },
  // publicEvents: {
  //   get: (id: string) => ({
  //     type: 'api/publicevents/get',
  //     payload: api.publicEvents.get(id)
  //   })
  // },
  publicAssets: {
    get: (id: string) => ({
      type: 'api/publicassets/get',
      payload: api.publicAssets.get(id),
    }),
    getEventList: (id: string, nextPageToken?: string) => ({
      type: 'api/publicassets/getEventList',
      payload: api.publicAssets.getEventList(id, nextPageToken),
      meta: {
        nextPageToken,
        assetId: id,
      },
    }),
  },
  assets: {
    get: (id: string) => ({
      type: 'api/assets/get',
      payload: api.assets.get(id),
    }),
    getList: (params?: any) => ({
      type: 'api/assets/getList',
      payload: api.assets.getList(params),
    }),
    getListFiltered: (params: AssetsFilterParams, nextPageToken?: string) => ({
      type: 'api/assets/getListFiltered',
      payload: api.assets.getListFiltered(params, nextPageToken),
      meta: {
        nextPageToken,
      },
    }),
    getEventList: (id: string, nextPageToken?: string) => ({
      type: 'api/assets/getEventList',
      payload: api.assets.getEventList(id, nextPageToken),
      meta: {
        nextPageToken,
        assetId: id,
      },
    }),
    create:
      (
        asset: RkvstCreateAsset,
        setAlert: (p: AlertData) => void,
        setLoading: Dispatch<SetStateAction<boolean>>,
        onError?: (p: Error) => void
      ) =>
      async (dispatch: Dispatch<any>, getState: any) => {
        try {
          setLoading(true)
          dispatch({ type: 'api/assets/create_PENDING' })
          const result = await api.assets.create(asset)
          dispatch({ type: 'api/assets/create_FULFILLED', payload: result })
          setAlert({
            type: 'success',
            message: `Asset ${result?.attributes?.arc_display_name} Registered`,
            timeout: 4000,
          })
        } catch (err) {
          dispatch({ type: 'api/assets/create_REJECTED' })
          setAlert({
            type: 'error',
            err,
            timeout: 4000,
          })
          onError && onError(err)
        } finally {
          setLoading(false)
        }
      },
    startTracking: (id: string) => ({
      type: 'api/assets/startTracking',
      payload: api.assets.startTracking(id),
    }),
    stopTracking: (id: string) => ({
      type: 'api/assets/stopTracking',
      payload: api.assets.stopTracking(id),
    }),
    createEvent: (id: string, event: RkvstEvent) => async (dispatch: Dispatch<any>) => {
      const payload = await api.assets.createEvent(id, event)
      dispatch({
        type: 'api/assets/createEvent',
        payload,
        meta: {
          assetId: id,
        },
      })
    },
    getTransactions: (assetId: string, eventId: string, nextPageToken?: string) => ({
      type: 'api/assets/getTransactions',
      payload: api.assets.getTransactions(assetId, eventId, nextPageToken),
    }),
    getCaps: () => async (dispatch: Dispatch<any>) => {
      try {
        const response = await api.assets.getCaps()
        dispatch({ type: 'api/assets/getCaps_FULFILLED', payload: response })
      } catch {
        dispatch({ type: 'api/assets/getCaps_FAILED' })
      }
    },
  },
  locations: {
    getList: (params?: any) => async (dispatch: Dispatch<any>) => {
      try {
        dispatch({ type: 'api/locations/getList_PENDING' })
        const result = await api.locations.getList(params)
        dispatch({ type: 'api/locations/getList_FULFILLED', payload: result })
      } catch (err) {
        dispatch({ type: 'api/locations/getList_REJECTED' })
      }
    },
    getListFiltered: (nextPageToken?: string) => ({
      type: 'api/locations/getListFiltered',
      payload: api.locations.getListFiltered(nextPageToken),
      meta: {
        nextPageToken,
      },
    }),
    create: (location: RkvstLocation) => ({
      type: 'api/locations/create',
      payload: api.locations.create(location),
    }),
    update: (id: string, location: RkvstLocation) => ({
      type: 'api/locations/update',
      payload: api.locations.update(id, location),
    }),
    delete: (id: string) => ({
      type: 'api/locations/delete',
      payload: api.locations.delete(id),
      meta: { id }, // so id can be accessed in reducer
    }),
    getCaps: () => async (dispatch: Dispatch<any>) => {
      try {
        const response = await api.locations.getCaps()
        dispatch({ type: 'api/locations/getCaps_FULFILLED', payload: response })
      } catch {
        dispatch({ type: 'api/locations/getCaps_FAILED' })
      }
    },
  },
  archivistnode: {
    get: (params: any) => ({
      type: 'api/archivistnode/get',
      payload: api.archivistnode.get(params),
    }),
  },
  whoami: {
    get: () => ({
      type: 'api/whoami/get',
      payload: api.whoami.get(),
    }),
  },
  subjects: {
    get: (identity: string) => ({
      type: 'api/subjects/getSubject',
      payload: api.subjects.getOne(identity),
    }),
    getList: () => ({
      type: 'api/subjects/getList',
      payload: api.subjects.getList(),
    }),
  },
  identityproviders: {
    get: (params: any) => ({
      type: 'api/identityproviders/get',
      payload: api.identityproviders.get(params),
    }),
  },
  rootPrincipals: {
    update_ui: (rootPrincipals: any[]) => ({
      type: 'root_principals_update',
      payload: { rootPrincipals },
    }),
    getList: () => ({
      type: 'api/rootPrincipals/getList',
      payload: api.rootPrincipals.getList(),
    }),
  },
  sampleSets: {
    getList: () => ({
      type: 'api/sampleSets/getList',
      payload: api.sampleSets.getList(),
    }),
    create: (dataset: any) => ({
      type: 'api/sampleSets/create',
      payload: api.sampleSets.create(dataset),
    }),
  },
  tenancies: {
    getCaps: () => ({
      type: 'api/tenancies/getCaps',
      payload: api.tenancies.getCaps(),
    }),
  },
  accessPolicies: {
    getCaps: () => async (dispatch: Dispatch<any>) => {
      try {
        const response = await api.accessPolicies.getCaps()
        dispatch({ type: 'api/accessPolicies/getCaps_FULFILLED', payload: response })
      } catch {
        dispatch({ type: 'api/accessPolicies/getCaps_FAILED' })
      }
    },
  },
  blobs: {
    getCaps: () => async (dispatch: Dispatch<any>) => {
      try {
        const response = await api.blobs.getCaps()
        dispatch({ type: 'api/blobs/getCaps_FULFILLED', payload: response })
      } catch {
        dispatch({ type: 'api/blobs/getCaps_FAILED' })
      }
    },
  },
}
