import AssetList from '@app/components/Assets/AssetList'
import { Img, Modal } from '@ui/components'
import React from 'react'

import { modalStyles } from './LocationAssetsModal.styles'
import { LocationAssetsModalProps } from './LocationAssetsModal.types'

const LocationAssetsList: React.FC<LocationAssetsModalProps> = (props) => {
  const assets = props.location
    ? props.assets.filter((asset) => asset.attributes.arc_home_location_identity === props.location?.identity)
    : props.unresolvedLocationAssets

  const locations = props.location ? [props.location] : []

  return <AssetList assets={assets} locations={locations} limit={props.limit} />
}

const Presentation: React.FC<LocationAssetsModalProps> = (props) => (
  <Modal onClose={props.onClose} className="location-assets" css={modalStyles} size="large">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h3>
            <Img name="Plant-Location-Colour" alt="bin" />
            <span>{props.location ? props.location.display_name : 'Assets with no location'}</span>
          </h3>
        </div>
      </div>
      <div className="row">
        <LocationAssetsList {...props} />
      </div>
    </div>
  </Modal>
)

const LocationAssetsModal = Presentation

export default LocationAssetsModal
