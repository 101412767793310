import accessPolicies from './resources/accessPolicies'
import appRegistrations from './resources/appRegistrations'
import archivistnode from './resources/archivistnode'
import assets from './resources/assets'
import blobs from './resources/blobs'
import dropboxConnection from './resources/dropboxConnection'
import events from './resources/events'
import identityproviders from './resources/identityproviders'
import inviteUsers from './resources/inviteUsers'
import locations from './resources/locations'
import members from './resources/members'
import memberships from './resources/memberships'
import publicAssets from './resources/publicAssets'
import rootPrincipals from './resources/rootPrincipals'
import sampleSets from './resources/sampleSets'
import subjects from './resources/subjects'
import tenancies from './resources/tenancies'
import users from './resources/users'
import whoami from './resources/whoami'

export default {
  assets,
  blobs,
  events,
  publicAssets,
  locations,
  archivistnode,
  whoami,
  identityproviders,
  rootPrincipals,
  subjects,
  sampleSets,
  tenancies,
  accessPolicies,
  appRegistrations,
  users,
  inviteUsers,
  dropboxConnection,
  memberships,
  members,
}
