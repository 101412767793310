interface PieChartProps {
  safeTestId?: string
  trackColor?: string
  lineColor?: string
  percent?: number | string
  children?: React.ReactNode
  radius?: number
  className?: string
}
import { useTheme } from 'styled-components'

export const PieChart = (props: PieChartProps) => {
  const radius = props.radius || 50

  return (
    <svg
      focusable="false"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      tabIndex={-1}
      width={radius * 4}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      data-test={`${props.safeTestId}-graphic`}
      className={props.className}
    >
      <circle
        data-test-id="track"
        cx={radius}
        cy={radius}
        r={Math.round(0.85 * radius)}
        stroke={props.trackColor}
        strokeWidth={Math.round(0.15 * radius)}
        strokeDasharray={`100 100`}
        fill="none"
        pathLength="100"
        transform={`rotate(-90 ${radius} ${radius})`}
      />
      {props.percent && (
        <circle
          data-test-id="bar"
          cx={radius}
          cy={radius}
          r={Math.round(0.85 * radius)}
          stroke={props.lineColor}
          strokeWidth={Math.round(0.15 * radius)}
          strokeDasharray={`${props.percent} 100`}
          strokeLinecap="round"
          fill="none"
          pathLength="100"
          transform={`rotate(-90 ${radius} ${radius})`}
        />
      )}

      {props.children}
    </svg>
  )
}
