import i18n from './i18n'

// prettier-ignore
export default {
  onboardingSuccess: `/onboarding/:sampleType`,
  auditEvent: (props?: {assetId: string, eventId: string}) => `/auditor/assets/${props ? props.assetId : ':assetId'}/events/${props ? props.eventId : ':eventId'}`,
  auditor: `/auditor`,
  assets: `/assets`,
  publicassets: `/archivist/publicassets`,
  asset: (props?: { id: string }) => `/assets/${props ? props.id : ':assetId'}`,
  event: (props?: { assetId: string, eventId: string }) => `/assets/${props ? props.assetId : ':assetId'}/events/${props ? props.eventId : ':eventId'}`,
  documentSearch: '/instaproof',
  publicAsset: (props?: { id: string }) => `/archivist/publicassets/${props ? props.id : ':assetId'}`,
  publicEvent: (props?: { assetId: string, eventId: string }) => `/archivist/publicassets/${props ? props.assetId : ':assetId'}/events/${props ? props.eventId : ':eventId'}`,
  locations: `/locations`,
  archivist: `/archivist`,
  archivistTab: `/archivist/:tab`,
  integrations: `/archivist/integrations`,
  accessPolicies: `/access-policies`,
  accessPolicy: (props?: { identity: string }) => `/access-policies/${props ? props.identity : ':policyId'}`,
  /*
  TODO AB#8462: enable this when user-persona onboarding page is ready
  onboardingDeveloper: `/onboarding/:developer`,
  onboardingUser: `/onboarding/:user`,
  onboardingSelector: `/onboarding`,
  */
  onboardingDeveloper: `/onboarding`,
  onboardingUser: `/onboardinguser`,
  onboardingSelector: `/onboardingselector`,
  transaction: (props?: {
    eventId: string, assetId: string, transactionId: string
  }) => `/transaction/${
    props ? `${props.assetId}/${props.eventId}/${props.transactionId}`
      : ':assetId/:eventId/:transactionId'
  }`,
  merkleLogEntry: `/merklelogentry/:assetId/:eventId`,
  publicTransaction: (props?: {
    eventId: string, assetId: string, transactionId: string
  }) => `/publictransaction/${
    props ? `${props.assetId}/${props.eventId}/${props.transactionId}`
      : ':assetId/:eventId/:transactionId'
  }`,

  // specific logged out routes with appropriate messaging for log out reason
  loggedOut: `/logged-out`,
  unauthorised: `/logged-out?e=${encodeURI(i18n.t('_insuff_role').toString())}`,
  // don't show error message on simple logout - it makes the page unnecessarily messy
  loginExpired: `/logged-out`,
  developerConsole: '/developers',
}
