import assetsApi from '@app/api/resources/assets'
import suggestions from '@app/api/resources/suggestions'
import { SuggestionsKey } from '@app/hooks/hooks.types'
import { useQuery } from '@tanstack/react-query'
import { RkvstAsset } from '@ui/types'
import { useState } from 'react'
const PQueue = require('p-queue')

const queue = new PQueue.default({ concurrency: 5 })

let clearFlag = 0

interface Params {
  key?: SuggestionsKey
  staleTime?: number
  force?: boolean
  limit?: number
}

/**
 * Query to suggestions api for asset attributes
 */
export const useSuggestedAssets = (textSearch: string, params: Params) => {
  if (params.force) {
    clearFlag += 1
  }

  const result = useQuery<string[]>({
    queryKey: [textSearch, 'LoadSuggestedAssets', clearFlag, JSON.stringify(params)],
    queryFn: async () => {
      const res: any = await suggestions.get(
        'asset',
        textSearch,
        true,
        'attribute',
        params.limit || 10,
        'v',
        params.key
      )

      return [...(res as string[])]
    },
    keepPreviousData: true,
    staleTime: params.staleTime,
  })

  const loading = result.isLoading || result.isFetching || result.isRefetching
  return {
    ...result,
    loading,
  }
}

/**
 * Query to suggestions api for asset attributes
 * it will fetch the first asset that matches the display name and it will return
 * this instead of the suggested string. Is a kind of the google "i will be lucky"
 * search
 */
export const useIWillBeLuckySuggestedAssets = (textSearch: string, params: Params) => {
  if (params.force) {
    clearFlag += 1
  }

  const result = useQuery<RkvstAsset[]>({
    queryKey: [textSearch, 'LoadLuckySuggestedAssets', clearFlag, JSON.stringify(params)],
    queryFn: async () => {
      const res: any = await suggestions.get(
        'asset',
        textSearch,
        true,
        'attribute',
        params.limit || 10,
        'v',
        params.key
      )
      if (!res) {
        return [] as RkvstAsset[]
      }

      const assets: RkvstAsset[] = []

      for (let i = 0; i < res.length; i += 1) {
        queue.add(async () => {
          const data = await assetsApi.getListFiltered({ name: res[i], page_size: 1 }, '', undefined)
          if (data && data.assets && data.assets.length) {
            assets.push(data.assets[0])
          }
        })
      }

      await queue.onIdle()
      return assets
    },
    keepPreviousData: true,
    staleTime: 300,
  })

  const loading = result.isLoading || result.isFetching || result.isRefetching
  return {
    ...result,
    loading,
  }
}
