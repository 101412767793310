import { Button } from '@ui/components/src/components/atoms'
import { useState } from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '../../../svgs'
import { HeaderCard } from './Cards'
import { CollapsibleHeaderCardProps } from './Cards.types'

/**
 * CollapsibleHeaderCard is a HeaderCard that adds a button to the top-right corner of the card
 * for toggling the collapsed status of the card. If rightItems are supplied, it will be appended
 * to the end of the array.
 */
export const CollapsibleHeaderCard = (props: CollapsibleHeaderCardProps) => {
  const { rightItems, children, startClosed } = props
  const [open, setOpen] = useState<boolean>(startClosed || true)

  return (
    <HeaderCard
      rightItems={[
        ...(rightItems ? (Array.isArray(rightItems) ? rightItems : [rightItems]) : ([] as React.ReactNode[])),
        !open ? (
          <Button
            key="open"
            testTag={props.testTag + '-expand'}
            buttonFunction="normal"
            variant="link-grey"
            onClick={() => setOpen(true)}
            icon={<ChevronDownIcon />}
          />
        ) : (
          <Button
            key="close"
            testTag={props.testTag + '-collapse'}
            buttonFunction="normal"
            variant="link-grey"
            onClick={() => setOpen(false)}
            icon={<ChevronUpIcon />}
          />
        ),
      ]}
      {...props}
    >
      {open && children}
    </HeaderCard>
  )
}
