import { brandColors, mainTheme, pickColor } from '@ui/themes'
import { createGlobalStyle } from 'styled-components'

export const theme = mainTheme

export const layout = mainTheme.layout

const Css = createGlobalStyle`

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  body {
	// Default text
	font-family: ${theme.typography.text.fontFamily};
	font-size: 16px;
    color: ${theme.typography.text.color};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: auto;
    margin: 0;
    overflow-x: hidden;

    background-color: #F7F7F9;
  }

  h1, h2 {
	font-family: ${theme.typography.heading.fontFamily};
	font-size: ${theme.typography.heading.fontSize};
	line-height: ${theme.typography.heading.lineHeight};
	color: ${theme.typography.heading.color};
	font-weight: 700;
	letter-spacing: 0.05ch;
  }

  p, input, button, .__react_component_tooltip, textarea {
	font-family: ${theme.typography.text.fontFamily};
	font-size: ${theme.typography.text.fontSize};
	line-height: ${theme.typography.text.lineHeight};
	color: ${theme.typography.text.color};
  }

  th, .attribute-table-pseudoheader {
	font-family: ${theme.typography.text.fontFamily};
	font-size: ${theme.typography.text.fontSize};
	line-height: ${theme.typography.text.lineHeight};
	color: ${theme.typography.subheading.color};
	font-weight: 500;
  }

  label, h3, h4 {
	font-family: ${theme.typography.subheading.fontFamily};
	font-size: ${theme.typography.subheading.fontSize};
	line-height: ${theme.typography.subheading.lineHeight};
	color: ${theme.typography.subheading.color};
	font-weight: 500;
	letter-spacing: 0.025ch;
  }
  
  .alertText {
	font-family: ${theme.typography.subheading.fontFamily};
	font-size: ${theme.typography.subheading.fontSize};
	line-height: ${theme.typography.subheading.lineHeight};
	font-weight: 500;
	letter-spacing: 0.025ch;
  }

  .helpText {
	font-family: ${theme.typography.helpText.fontFamily};
	font-size: ${theme.typography.helpText.fontSize};
	line-height: ${theme.typography.helpText.lineHeight};
	letter-spacing: ${theme.typography.helpText.letterSpacing};
	color: ${theme.typography.helpText.color};

	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-bottom: 4px;
  }

  .monospace {
	font-family: ${theme.typography.code.fontFamily};
	font-size: ${theme.typography.code.fontSize};
	line-height: ${theme.typography.code.lineHeight};
	color: ${theme.typography.code.color};
  }

  .code {
	font-family: ${theme.typography.code.fontFamily};
	font-size: ${theme.typography.code.fontSize};
	line-height: ${theme.typography.code.lineHeight};
	color: ${theme.typography.code.color};

	border: 1px solid ${brandColors.highlightGrey};
	border-radius: 8px;
  }

  .multiline {
	white-space: pre-wrap;
	word-wrap: break-word;
  }

  a:link {
	color: ${theme.colors.link};
	background-color: transparent;
	text-decoration: underline;
	text-decoration-color: ${theme.colors.underline};
  }

  a:visited {
	color: ${theme.colors.visited_link};
	background-color: transparent;
	text-decoration: underline;
	text-decoration-color: ${theme.colors.underline};
  }

  a:hover {
	color: ${theme.colors.underline};
	background-color: transparent;
	text-decoration: underline;
  }

  *:focus {
    outline:none;
  }

  hr {
    width: 100%;
    margin-bottom: 24px; 
    border-top: none;
    color: ${pickColor(theme?._v1, 'grey', 'i200')};
  }

  .dark {
    color: #707070;
    a, a:focus, a:visited {
      color: #707070;
    }
    a:hover {
      color: #fff;
    }
    h1, h2, h3, h4 {
      color: #fff;
    }
  }


  .mob-only {
    @media(min-width: ${theme.layout.grid.min.lg}px) {
      display: none;
    }
  }

  .mob-only-top {
    padding-bottom: 15px;
  }

  main {
    @media (min-width: ${theme.layout.fixedNavHeaderBreakpoint}px) {
      padding-top: 0;
      margin-top: 0;
    }
  }

main > section, main > article {
	padding-top: 15px;

	h2 {
		margin-top: 20px;
		margin-bottom: 24px;

		@media(max-width: ${theme.layout.grid.max.md}px) {
			margin-top: 0;
			margin-bottom: 30px;
		}
	}

	.widget {
		position: relative;
		background: #fff;
		box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
		height: calc(100% - 15px);
		margin-bottom: 15px;
		padding: 30px;

		@media (max-width: ${theme.layout.grid.max.sm}px) {
			padding: 25px 10px;
		}
	}
}

  .profile {
    &__message {
      margin-bottom: 27px;
      display: block;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .button-left {
    text-align: left;
    max-width: unset;
  }

  .button-right {
    text-align: right;
    max-width: unset;
  }

  .items-right {
	@media (min-width: ${theme.layout.fixedNavHeaderBreakpoint}px) {
	  float: right;
	}
  }

  .text-vertical-middle {
  	vertical-align: middle;
  }

  .error {
    color: ${theme.colors.error};
  }

  .message {
  }
  .high_emphasis {
    color: ${theme.colors.high_emphasis}
  }

  /* _buttons.scss ------------------------------- */
  button {
    display: inline-block;
    cursor: pointer;
    color: ${theme.colors.brand.body};
	font-weight: 500;
	letter-spacing: 0.02em;
  }

  .button--disabled {
    opacity: 0.25
  }

  button:focus {
    outline:0;
  }

  a.button {
    display: inline-block;
    padding-top: 15px;
    text-decoration: none;
    color: ${theme.colors.brand.body};
  }

  button.button-transparent,
  button.button--transparent,
  a.button-transparent {
    border: none;
    background: transparent;
  }

  button.button--outline,
  a.button--outline {
    border: 1px solid ${theme.colors.brand.body};
    background: transparent;
  }

  button.button-arrow,
  a.button-arrow {
    line-height: 12px;
    span {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  button.button-action,
  a.button-action {
    background:linear-gradient(#283447,#344054);
    border: none;
    color: #fff;
    border-radius: 3px;
    text-transform: uppercase;
    min-width: 170px;
    height: 45px;
    padding: 0 20px;
    min-height: 50px;
    margin-right: 24px;

    @media(max-width: ${theme.layout.grid.max.md}px) {
      padding: 0 20px;
      min-width: 150px;
    }

    img {
			margin-right: 16px;
			vertical-align: middle;
			max-height: 24px;
    }
  }

  button.button-action.loading-wait {
    cursor: wait;
  }

  button.button--short {
	height: 2rem;
  }
  
  button.button-action:last-child {
    margin-right: 0;
  }

  button.button-action-cancel	{
    background-color: rgba(${theme.colors.brand.grey}, 0.5);
  }
  button.button-action.button-action-transparent {
    background: transparent;
    color: ${theme.colors.error};
    border: 1px solid ${theme.colors.error};
  }

  button.button-icon-only {
    min-width: 0;
    img {
      margin-right: 0;
    }
  }

  button.button-grey {
    background-color: #F6F6F6;
    color: #858585;
  }

  button.button-action {
    background: none;
    transition: background-color 0.2s linear, color 0.3s linear;
    background-color: ${theme.colors.brand.blue};
    border-radius: 8px;
    white-space: nowrap;
  }

  button.button-action.disabled {
    background-color: #f2f2f2;
    color: #858585;
  }
/* end _buttons.scss */

/* tables.scss ---------------- */
/* TODO: remove this once we move all tables to use Table component */
table.legacy-table {
	border-collapse: collapse;
	width: 100%;
	text-align: left;
	thead {
		th {
			white-space: nowrap;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	tbody {
		tr {
			border-left: 3px solid ${theme.colors.brand.blue};
			border-bottom: 1px solid #DEDEDE;
			td {
				padding: 16px 10px;
				width: auto;
        word-break: break-all;
			}
      & a.clean-link {
        display: block;
        margin: -16px;
        padding: 16px;
        text-decoration: none;
        cursor: inherit;
        & span {
          cursor: default
        }
      }
      & a:visited.clean-link {
        text-decoration: none;
      }
			td:first-of-type {
				padding-left: 20px;
			}
			td.icon {
				width: 40px;
				position: relative;
				.circle {
					position: absolute;
					top: -8px;
					left: -9px;
					background-color: #fff;
					border: 2px solid ${theme.colors.brand.blue};
					height: 16px;
          width: 16px;
          border-radius: 8px;
				}
			}
			td.id {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 50px;
			}
			td.idpbanner {
				background-color: #FCFCFC;
			}
			td.idpleft {
				width: 20%;
			}
		}
		tr.clickable:hover {
			background-color: ${theme.colors.highlight};
			cursor:pointer;
		}
	}
	@media(max-width: ${theme.layout.grid.max.md}px) {
		tbody {
			tr {
				border-left: none;
				td {
					padding-top: 10px;
					padding-bottom: 10px;
				}
				td.icon {
					width: 20px;
					padding-left: 0;
					padding-right: 0;
					.circle {
						display: none;
					}
				}
			}
		}
	}
}
table.small tbody tr td {
  padding: 10px 10px;
}
table.clickablerows tbody {
	tr:hover {
		background-color: ${theme.colors.highlight};
		cursor:pointer;
	}
}
/* end tables.scss */

/* animations */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes slideInLeft {
	from {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	from {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

@-webkit-keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

/* _forms.scss ------------ */
label, .label {
	display: block;
	margin-bottom: 5px;
}

.input {
	padding-bottom: 20px;

  & .--inside-table {
    border: 0.5px solid ${theme.colors.brand.inputBorder};
    color: ${theme.colors.brand.grey}
  }

	input,
	textarea,
	.select-wrapper {
		position: relative;
		border: 0.5px solid ${theme.colors.brand.inputBorder};
		height: 35px;
		width: 100%;
		border-radius: 3px;
		padding-left: 13px;
		padding-right: 30px;
	}
  .select-wrapper {
    padding-right: 13px;
    select {
			width: 100%;
			height: 100%;
			background: none;
			border: none;
      padding-right: 17px;
      cursor: pointer;
			-moz-appearance: none;
			-webkit-appearance: none;
			&::-ms-expand {
				display: none;
			}
		}
		img.arrow {
			position: absolute;
			top: 14px;
			right: 13px;
      pointer-events: none;
		}
  }
	input,
	textarea {
		padding-left: 13px;
		padding-right: 13px;
	}
	textarea {
		height: 94px;
		padding-top: 13px;
		padding-bottom: 13px;
	}

	textarea.large {
			height: 140px;
	}

}
.input.select-using-api-model {
	.background {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 2;
	}
	.select-wrapper {
		.selected {
			cursor: pointer;
			height: inherit;
			padding-top: 8px;
			.placeholder {
				display: block;
				// margin-top: 4px;
			}
			table {
				tbody {
					tr {
						border-left: none;
						border-bottom: none;
						td {
							padding: 0;
						}
					}
				}
			}
			img.search {
				position: absolute;
				top: 9px;
				right: 10px;
			}
		}
		.disabled {
			cursor: default;
			height: inherit;
			padding-top: 8px;
			table {
				tbody {
					tr {
						border-left: none;
						border-bottom: none;
						td {
							padding: 0;
						}
					}
				}
			}
		}
		.options {
			position: absolute;
			top: 33px;
			left: 0;
			width: 100%;
			max-height: 265px;
			overflow-x: auto;
			padding-top: 10px;
			background: #fff;
			z-index: 3;
			border: 0.5px solid ${theme.colors.brand.inputBorder};
			-webkit-border-bottom-right-radius: 3px;
			-webkit-border-bottom-left-radius: 3px;
			-moz-border-radius-bottomright: 3px;
			-moz-border-radius-bottomleft: 3px;
			border-bottom-right-radius: 3px;
			border-bottom-left-radius: 3px;
			table {
				tr {
					cursor: pointer;
					border-left: none;
					td {
						padding: 10px;
					}
				}
				tr:last-child {
					border-bottom: 0;
				}
			}
		}
		td.icon {
			width: 20px;
      &--copy__grey {
        opacity: 0.5;
      }
		}
	}
}
.profile-attributes{
	display: flex inline;
	padding: 10px;
	width:50%;
	min-width: 300px;
}

.profile-inputs{
	width:100%;
}

.input.select-using-api-model.open .select-wrapper {
	-webkit-border-bottom-right-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-bottomright: 0;
	-moz-border-radius-bottomleft: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.input-error {
	.error-message {
		display: block;
		margin-top: 5px;
		color: #FF0000;
	}
}

.modal-form {
	width: 95%;
}

/* end of forms.scss */

/* tabs.scss ------------- */
.react-tabs__tab-list {
	padding-left: 0;
	margin-top: 0;
	// TODO: Check that padding-top change doesn't break anything.
}

@media (min-width: ${theme.layout.grid.min.lg}px) {
	.react-tabs__tab-list {
		border: 0;
		align-items: stretch;
		display: flex;
		justify-content: flex-start;
		margin-bottom: -1px;
	}
}

.react-tabs__tab {
	font-family: ${theme.typography.heading.fontFamily};
	font-size: clamp(${theme.typography.subheading.fontSize});
	font-weight: 600;
	list-style: none;

	@media (max-width: ${theme.layout.grid.max.md}px) {
		display: inline-block;
		text-align: center;
  }

  align-items: center;
	color: inherit;
	margin: 0px;
	padding: 12px;
	text-align:center;
	min-width:100px;
  text-decoration: none;
  cursor: pointer;
	@media (max-width: ${theme.layout.grid.max.md}) {
		display: block;
		padding: 10px;
		text-align: center;
	}
}

.react-tabs__tab--selected {
	color: rgb(11, 74, 111);
	background-color: rgb(224, 242, 254);
	border-bottom: 3px solid;
}

.react-tabs__tab.react-tabs__tab--selected {
	border-radius: 8px 8px 0px 0px;
	z-index: 2;
}

.react-tabs__tab-panel > div {
	padding: 30px 0;
}

@media (min-width: ${theme.layout.grid.min.md}px) {
	.react-tabs__tab-panel > div {
		border-top: 0.5px solid rgb(170, 170, 170);
	}
}
/* end of tabs.scss */

/* styled for reach-ui */
.shadow-popup {
  z-index: 16; /* 1 above modal z-index */

  ul > li {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.clickable {
  cursor: pointer;
}

.hide {
	display: none;
}

.show {
	display: block
}

.legacy-overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
`

export default Css
