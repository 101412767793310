/**
 * Allows to wrap a dynamic import of a function in a
 * placeholder that will be automatically swap for the
 * real one onces the dynamic import is loaded
 *
 * @param fileName [string] fileName to dynamiclly import
 * @param defaultFn [optional] placeholder function that will be called
 *                          meanwhile the dynamic import is not loaded
 *
 * @usage
 *
 * import dynamicImportPlaceHolder from "./dynamicImportPlaceHolder";
 *
 * type ExportExcel = (data: any, fileName?: string) => void;
 *
 * export const exportExcel = <ExportExcel>(
 *   dynamicImportPlaceHolder(import("./_exportExcel"))
 * );
 */
export default <T>(dynamicImport: Promise<any>, defaultFn?: any): T => {
  let fn: any = defaultFn || ((...args: any[]) => {});

  dynamicImport.then((m) => (fn = m.default));

  const fnRet: any = (...args: any[]) => {
    return fn(...args);
  };

  return fnRet as T;
};
