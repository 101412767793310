import AlertFailedIcon from '@app/common/Icons/AlertFailedIcon'
import AlertInfoIcon from '@app/common/Icons/AlertInfoIcon'
import AlertSuccessIcon from '@app/common/Icons/AlertSuccessIcon'
import { Img } from '@ui/components'
import { uniqueId } from '@ui/utils/src/uniqueId'
import React, { useState } from 'react'

import { escapeValue } from '../../utils/escapeValue'
import { Container } from './Alerts.style'
import { AlertData, AlertType, WithAlertsContext } from './Alerts.types'

export const AlertsContext = React.createContext<WithAlertsContext>({
  setAlert: (alertData: AlertData) => {},
  clearAlerts: () => {},
})

export const createAlert = (alertData: AlertData): AlertType | undefined => {
  let message

  // Guard against incorrect type of notification being passed
  if (!['success', 'info', 'error'].includes(alertData.type)) return

  // Guard to cope with object with property "message"
  // "message" property of passed object will be used for notification
  if (alertData.message && typeof alertData.message !== 'string') {
    const alert: any = alertData.message

    // check to see if the object passed has a "message" property
    if (alert?.message && typeof alert.message === 'string') {
      alertData.message = alert.message
    }
  }

  if (alertData.message !== undefined) {
    message = alertData.message
  } else if (
    // @ts-ignore
    alertData?.err?.response?.data?.message
  ) {
    // @ts-ignore
    message = `Error: ${alertData.err.response.data.message}`
  } else {
    // generic fallback error message in case neither message or
    // an approriate API error with a message field in the response are provided
    message = 'An Error occurred, please try later.'
  }

  return {
    id: uniqueId(),
    message,
    body: alertData.body,
    type: alertData.type,
    timeout: alertData.timeout || 4000,
  }
}

interface AlertsProps {
  show: boolean
  children?: React.ReactNode
  navVisible?: boolean
}

const Presentation: React.FC<AlertsProps> = (props) => {
  const [alerts, setAlerts] = useState<Array<AlertType>>([])

  const removeAlert = ({ id }: AlertType) => {
    const alertsCopy: Array<AlertType> = []
    alerts.forEach((alert) => {
      if (alert.id !== id) {
        alertsCopy.push(alert)
      }
    })
    setAlerts(alertsCopy)
  }

  const setAlert = (alertData: AlertData) => {
    const alert = createAlert(alertData)
    if (alert) {
      setAlerts([...alerts, alert])
      if (alert.timeout && alert.timeout > 0) {
        setTimeout(() => {
          removeAlert(alert)
        }, alert.timeout)
      }
    }
  }
  const clearAlerts = () => {
    setAlerts([])
  }

  const iconForAlert = (alert_type: string) => {
    switch (alert_type) {
      case 'success':
        return <AlertSuccessIcon />
        break

      case 'error':
        return <AlertFailedIcon />
        break

      case 'info':
        return <AlertInfoIcon />
        break

      default:
        return <AlertInfoIcon />
        break
    }
  }

  return (
    <AlertsContext.Provider
      value={{
        setAlert,
        clearAlerts,
      }}
    >
      {props.children}
      {props.show && (
        <Container id="alerts" data-test="alerts-notification" navVisible={props.navVisible}>
          {alerts.map((alert, i) => (
            <div key={`alert-${i}`} className={`container-fluid alert ${alert.type}`}>
              <div className="row">
                <div className="col-12 message">
                  <span id="alert_icon" className="alertIcon">
                    {iconForAlert(alert.type)}
                  </span>
                  <span id="alert_message" className="alertText contents">
                    {alert.body ? alert.body : escapeValue(alert.message)}
                  </span>
                  <div className="col-1 close text-center">
                    <a
                      className="button-transparent clickable"
                      href="#"
                      data-test="alert-close-button"
                      onClick={() => {
                        removeAlert(alert)
                      }}
                    >
                      <Img name="close-icon" alt="X" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Container>
      )}
    </AlertsContext.Provider>
  )
}

export function withAlertsContext<T>(Wrapped: React.ComponentType<T & WithAlertsContext>) {
  return (wrappedComponentProps: T) => {
    return (
      <AlertsContext.Consumer>
        {({ setAlert, clearAlerts }: WithAlertsContext) => (
          <Wrapped clearAlerts={clearAlerts} setAlert={setAlert} {...wrappedComponentProps} />
        )}
      </AlertsContext.Consumer>
    )
  }
}

const Alerts = Presentation

export default Alerts
