import { useAppSelector } from '@app/hooks'
import useAlerts from '@app/hooks/useAlerts'
import { mapArrayById } from '@app/mapArrayById'
import {
  Combobox,
  ComboboxProps,
  DropResult,
  DropZone,
  DropZonePropsCategories,
  EllipsedLabel,
  SearchInputPropsCategories,
  TypeaheadCategoriesType,
} from '@ui/components'
import { RKVSTHashes } from '@ui/utils'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SuggestionsKey } from '../../../../hooks/hooks.types'
import { AssetsFilter } from '../../hooks/useAssetList'
import { useSuggestedAssets } from '../../hooks/useAssetSuggestions'
import { DropzoneCateogry, SearchInput } from './AssetList.styles'
import { Filter } from './AssetList.types'

type CategoryName =
  | 'displayName'
  | 'type'
  | 'tracked'
  | 'assetId'
  | 'hash'
  | 'version'
  | 'attestation'
  | 'dropboxPath'
  | 'location'
  | 'file'

interface CategoryDto extends CategoryParam {
  name: CategoryName
}

interface CategoryParam {
  title?: string
  meta?: any
  disabled?: boolean
  hash?: keyof RKVSTHashes
}

export type CategorySelection = CategoryName | CategoryDto

export const isDto = (category: CategorySelection): category is CategoryDto => {
  return (category as any).name !== undefined
}

// Sections has some shared categories so we centralize it here
export const useCategories = (
  selectedCategories: CategorySelection[],
  filter: Filter,
  setFilter: (filter: Filter) => void
) => {
  const { t } = useTranslation()

  const categories: Array<TypeaheadCategoriesType> = []

  selectedCategories.forEach((selectedCategory) => {
    const categoriesName = isDto(selectedCategory) ? selectedCategory.name : selectedCategory
    const categoryParams = isDto(selectedCategory) ? selectedCategory : {}

    if (categoriesName === 'displayName') {
      categories.push(useDisplayName(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'type') {
      categories.push(useDisplayType(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'location') {
      categories.push(useLocation(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'assetId') {
      categories.push(useAssetId(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'hash') {
      categories.push(useDocumentHash(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'file') {
      categories.push(useFile(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'version') {
      categories.push(useDocumentVersion(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'attestation') {
      categories.push(useAttestation(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'tracked') {
      categories.push(useTracking(filter, setFilter, categoryParams))
    }

    if (categoriesName === 'dropboxPath') {
      categories.push(useDropboxPath(filter, setFilter, categoryParams))
    }
  })

  const renderCategory = (category: number, props: any) => {
    const selectedCategory = selectedCategories[category]
    const categoriesName = isDto(selectedCategory) ? selectedCategory.name : selectedCategory

    switch (categoriesName) {
      case 'attestation':
      case 'tracked':
        return <Combobox className="category-filter" data-test="filter-combobox-value" {...props} />
      case 'assetId':
      case 'version':
        return <SearchInput type="text" className="category-filter" data-test="filter-input-value" {...props} />
      case 'file':
        return (
          <DropzoneCateogry className="category-filter">
            <DropZone variant="inline" {...props} />
          </DropzoneCateogry>
        )
    }
    // undefined will fall back to Typeahead
  }

  return {
    categories,
    renderCategory,
    categoriesTypes: selectedCategories.map((category) => (isDto(category) ? category.name : category)),
  }
}

const useDisplayName = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { data, loading } = useSuggestedAssets(search, { key: SuggestionsKey.ARC_DISPLAY_NAME, staleTime: 300 })

  const onChangeFilterName = useCallback(
    (name?: string) => {
      setFilter({ ...filter, name })
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('Display Name').toString(),
    id: 'display-name-typeahead',
    loading,
    placeholder: t('Search...').toString(),
    value: filter.name || '',
    preloaded: true,
    options: data || [],
    onChange: (search: string) => setSearch(search),
    onSelect: (name: string) => onChangeFilterName(name),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useDisplayType = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const { data, loading } = useSuggestedAssets(search, { key: SuggestionsKey.ARC_DISPLAY_TYPE, staleTime: 300 })

  const onChangeFilterName = useCallback(
    (type?: string) => {
      setFilter({ ...filter, type })
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('Type').toString(),
    id: 'display-type-typeahead',
    loading,
    placeholder: t('Search...').toString(),
    value: filter.type || '',
    options: data || [],
    preloaded: true,
    onChange: (search: string) => setSearch(search),
    onSelect: (name: string) => onChangeFilterName(name),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useDocumentHash = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType => {
  const { t } = useTranslation()

  const onChangeFilterName = useCallback(
    (hash?: string) => {
      setFilter({ ...filter, hash })
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('Hash').toString(),
    placeholder: t('Enter exact text...').toString(),
    onChange: (ev: React.KeyboardEvent) => onChangeFilterName((ev.target as any)?.value),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useFile = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): DropZonePropsCategories & { meta: any } => {
  const { t } = useTranslation()
  const { setAlert } = useAlerts()
  const [files, setFiles] = useState<File[]>([])
  // To detect that the hash of the filter is different than the one of the file
  const [myHash, setMyHash] = useState<string>('')
  const onDrop = (result: DropResult) => {
    setFiles([result.file])
    setFilter({ ...filter, hash: result.hashes[params.hash || 'sha256'] })
    setMyHash(params.hash || 'sha256')
  }
  const onClear = (file: File | null) => {
    setFiles([])
    setFilter({ ...filter, hash: undefined })
  }
  const onError = (err: string) => {
    setAlert({ type: 'error', message: err, timeout: 2000 })
  }
  useEffect(() => {
    if (filter.hash === undefined || filter.hash !== myHash) {
      setFiles([])
    }
  }, [filter, myHash])

  return {
    name: params.title !== undefined ? params.title : t('File').toString(),
    files,
    onDrop,
    onError,
    onClear,
    titlePlaceholder: t('Drop files here or click to filter'),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useDocumentVersion = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType => {
  const { t } = useTranslation()

  const onChangeFilterName = useCallback(
    (version?: string) => {
      setFilter({ ...filter, version })
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('Document Version').toString(),
    testTag: 'document-version-input',
    placeholder: t('Enter exact text...').toString(),
    onChange: (ev: React.KeyboardEvent) => onChangeFilterName((ev.target as any)?.value),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useLocation = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType => {
  const { t } = useTranslation()
  const locations = useAppSelector((state) => state.locations.list)
  const locationsById = mapArrayById(locations || [], 'identity')

  const filterOptions = (searchText: string) =>
    Object.keys(locationsById)
      .map((id) => {
        return locationsById[id]
      })
      .filter(({ display_name }) => display_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)

  const [options, setOptions] = useState(filterOptions(''))

  const loadSuggestedData = useCallback(
    (searchText: string) => {
      setOptions(
        [{ identity: 'unresolved', display_name: t('unresolved').toString() }].concat(filterOptions(searchText))
      )
    },
    [locationsById, setOptions]
  )

  const onChangeFilterName = useCallback(
    (location?: any) => {
      if (location?.identity === 'unresolved') {
        setFilter({ ...filter, locationId: undefined, hasLocations: false })
      } else {
        setFilter({ ...filter, locationId: location?.identity, hasLocations: undefined })
      }
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('Location').toString(),
    placeholder: t('Search...').toString(),
    id: 'display-filtename-typeahead',
    value:
      filter.hasLocations === false
        ? t('unresolved').toString()
        : (filter.locationId && locationsById[filter.locationId]?.display_name) || '',
    options,
    threshold: 0,
    onlySelectOptions: true,
    loadNewOptions: (search: string) => loadSuggestedData(search),
    mapValue: (location: any) => location.display_name,
    onSelect: (name: any) => onChangeFilterName(name),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useAttestation = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType<any> => {
  const { t } = useTranslation()

  const onChangeFilterName = useCallback(
    (attestation?: AssetsFilter['attestation']) => {
      setFilter({ ...filter, attestation })
    },
    [filter, setFilter]
  )

  const optionsText = {
    PRIVACY_UNSPECIFIED: t('All'),
    PUBLIC: t('Public'),
    RESTRICTED: t('Private'),
  }
  const options: Array<AssetsFilter['attestation']> = ['PRIVACY_UNSPECIFIED', 'RESTRICTED', 'PUBLIC']

  return {
    name: params.title !== undefined ? params.title : t('Visibility').toString(),
    selectedOption: Math.max(options.indexOf(filter.attestation), 0),
    options,
    renderOption: (opt) => optionsText[opt],
    onSelect: (opt: number) => onChangeFilterName(options[opt]),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useTracking = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType<any> => {
  const { t } = useTranslation()

  const onChangeFilterName = useCallback(
    (tracked?: AssetsFilter['tracked']) => {
      setFilter({ ...filter, tracked })
    },
    [filter, setFilter]
  )

  const optionsText = {
    TRACKED: t('Tracked'),
    NOT_TRACKED: t('Untracked'),
  }
  const options: Array<AssetsFilter['tracked']> = ['TRACKED', 'NOT_TRACKED']

  return {
    name: params.title !== undefined ? params.title : t('Tracked').toString(),
    selectedOption: Math.max(options.indexOf(filter.tracked), 0),
    options,
    renderOption: (opt) => optionsText[opt],
    onSelect: (opt: number) => onChangeFilterName(options[opt]),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useDropboxPath = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): TypeaheadCategoriesType => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const { data, loading } = useSuggestedAssets(search, { key: SuggestionsKey.DROPBOX_PATH, staleTime: 300 })

  const onChangeFilterName = useCallback(
    (dropboxPath?: string) => {
      setFilter({ ...filter, dropboxPath })
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('Dropbox Folder').toString(),
    loading,
    id: 'display-folder-typeahead',
    placeholder: t('Search...').toString(),
    value: filter.dropboxPath || '',
    options: data || [],
    preloaded: true,
    onChange: (search: string) => setSearch(search),
    onSelect: (name: string) => onChangeFilterName(name),
    disabled: params.disabled,
    meta: params.meta,
  }
}

const useAssetId = (
  filter: Filter,
  setFilter: (filter: Filter) => void,
  params: CategoryParam
): SearchInputPropsCategories => {
  const { t } = useTranslation()

  const onChangeFilterName = useCallback(
    (identity?: string) => {
      setFilter({ ...filter, identity })
    },
    [filter, setFilter]
  )

  return {
    name: params.title !== undefined ? params.title : t('ID').toString(),
    testTag: 'document-assetId-input',
    placeholder: t('Enter exact text...').toString(),
    onChange: (ev: React.KeyboardEvent) => onChangeFilterName((ev.target as any)?.value),
    disabled: params.disabled,
    meta: params.meta,
  }
}
