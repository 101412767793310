import { useTenanciesDataContext } from '@app/context/TenanciesDataContext'
import React, { Suspense } from 'react'
import { Location, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import paths from './paths'
import { MenuPlaceholder, SectionPlaceholder } from './suspense'
const Auditor = React.lazy(() => import('./pages/Auditor/Auditor'))
const NewAsset = React.lazy(() => import('./pages/Asset/Asset'))
const Locations = React.lazy(() => import('./components/Locations'))
const Archivist = React.lazy(() => import('./components/Archivist/Manage'))
const LoggedOut = React.lazy(() => import('./components/LoggedOutPage/LoggedOutPage'))

import { lazily } from 'react-lazily'
const { PublicTransaction, Transaction } = lazily(() => import('./components/Transaction'))
const { MerkleLogEntry, BlockChainLogEntry, PublicBlockChainLogEntry } = lazily(
  () => import('./pages/EventLogEntry/EventLogEntry')
)

const AccessPolicies = React.lazy(() => import('./components/AccessPolicies/AccessPolicies'))
const AccessPolicy = React.lazy(() => import('./components/AccessPolicies/AccessPolicy'))
const DeveloperConsole = React.lazy(() => import('./components/Developers/DeveloperConsole'))
import withRoleBasedAccess, { isCurrentBrowserRoutePublic, ROLES } from './roleBasedAccess'
const DocumentSearch = React.lazy(() => import('./components/DocumentSearch/DocumentSearch'))
import { getUserAttribute } from '@app/utils/userStats'

import { DocumentSearchSuspense } from './components/DocumentSearch/DocumentSearch.styles'

const { Onboarding } = lazily(() => import('./pages/Onboarding/Onboarding'))
const { OnboardingSelector } = lazily(() => import('./pages/OnboardingSelector/OnboardingSelector'))
const { OnboardingSuccess } = lazily(() => import('./pages/OnboardingSuccess/OnboardingSuccess'))
const { OnboardingUser } = lazily(() => import('./pages/OnboardingUser/OnboardingUser'))
import NewAssetList from './pages/Asset/AssetList'
import { useEventsListCount } from './pages/Auditor/hooks/useEventsList'
import { getFeatureFlag } from './utils/getFeatureFlag'

type OnRouteChangeProps = {
  action: (path: string, prevPath: string) => void
}

class OnRouteChangeWorker extends React.Component<
  {
    location: Location
  } & OnRouteChangeProps
> {
  componentDidUpdate(prevProps: { location: Location }) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action(this.props.location.pathname, prevProps.location.pathname)
    }
  }

  render() {
    return null
  }
}

const DefaultRoute = withRoleBasedAccess(() => {
  // First timers go to the Persona check. After that we remember their choice and
  // navigate to the appropriate onboarding page.

  // TODO: while we lack a rich 'userinfo' or 'tenantinfo' capability in the
  // back end we are limited in how we can track and store user preferences and
  // such. While a little fragile, we use a combination of application storage
  // (which doesn't survive changes of browser or private sessions) and Segment
  // (which doesn't survive ad-blockers) to hopefully support each other in
  // making most users' journeys pleasant.

  // TODO AB#8462: Note that through some cunning in paths.ts this code is safe to
  // commit and does not change the user experience yet. Once the user-specific
  // onboarding page is ready we'll switch up the routes and it'll start having an
  // effect
  const { whoAmI } = useTenanciesDataContext()
  const { data: totalEvents, loading } = useEventsListCount({})

  let currentpersona = localStorage.getItem(
    `${whoAmI?.principal?.issuer}::${whoAmI?.principal?.subject}_onboarding_persona`
  )
  if (!currentpersona) {
    //Try user stats instead...
    currentpersona = getUserAttribute('onboarding_persona')
  }

  if (totalEvents && !loading) {
    return <Navigate to={paths.auditor} />
  } else if (currentpersona == 'user' && !loading) {
    return <Navigate to={paths.onboardingUser} />
  } else if (currentpersona == 'dropbox' && !loading) {
    return <Navigate to={paths.onboardingUser} />
  } else if (currentpersona == 'developer' && !loading) {
    return <Navigate to={paths.onboardingDeveloper} />
  } else if (!loading) {
    return <Navigate to={paths.onboardingSelector} />
  } else {
    return <SectionPlaceholder height="100%" />
  }
})

const OnRouteChange: React.FC<OnRouteChangeProps> = (props) => {
  const location = useLocation()
  return <OnRouteChangeWorker action={props.action} location={location} />
}

const AllRoutes: React.FC = () => {
  // XXX TODO: This doesn't work properly when you can navigate between public and permissioned
  // pages. It snapshots the value on instantiation, and sticks with it. We must move to using
  // useLocation() inside Route - but there are also components where this value is known ahead
  // of time, and we can stop using it.
  const publicRoute = isCurrentBrowserRoutePublic()

  return (
    <Suspense fallback={<SectionPlaceholder height="100%" />}>
      <Routes>
        <Route path={paths.onboardingDeveloper} element={<Onboarding roles={[ROLES.administrator]} />} />
        <Route path={paths.onboardingSelector} element={<OnboardingSelector roles={[ROLES.administrator]} />} />
        <Route path={paths.onboardingSuccess} element={<OnboardingSuccess roles={[ROLES.administrator]} />} />
        <Route path={paths.onboardingUser} element={<OnboardingUser roles={[ROLES.administrator]} />} />

        <Route path={paths.auditor} element={<Auditor roles={[ROLES.administrator, ROLES.user]} />} />
        <Route path={paths.auditEvent()} element={<Auditor roles={[ROLES.administrator, ROLES.user]} />} />

        <Route
          path={paths.assets}
          element={<NewAssetList publicRoute={publicRoute} roles={[ROLES.administrator, ROLES.user]} />}
        />

        {/**
         * XXX: To achieve the desired routing behaviour for opening event sidebars, the asset
         * component is mounted for both the asset and event endpoints. To maintain the component
         * state I have supplied the *same* key.
         *
         * AFAIK this means it will re-render the component when the sidebar loads. Unless this
         * introduces performance issues, we suggest leaving it, as the code is quite simple.
         *
         * Discussion between Henry & Albert 05-Sep-2023.
         */}

        <Route
          path={paths.asset()}
          element={<NewAsset key="permissioned-shared" roles={[ROLES.administrator, ROLES.user]} />}
        />

        <Route
          path={paths.event()}
          element={<NewAsset key="permissioned-shared" roles={[ROLES.administrator, ROLES.user]} />}
        />

        <Route
          path={paths.publicEvent()}
          element={<NewAsset key="public-shared" roles={[ROLES.administrator, ROLES.user]} publicRoute={true} />}
        />

        <Route path={paths.publicAsset()} element={<NewAsset key="public-shared" publicRoute={true} />} />

        <Route path={paths.locations} element={<Locations roles={[ROLES.administrator, ROLES.user]} />} />
        <Route path={paths.publicTransaction()} element={<PublicBlockChainLogEntry publicRoute={true} />} />
        <Route path={paths.transaction()} element={<BlockChainLogEntry roles={[ROLES.administrator, ROLES.user]} />} />
        <Route
          path={paths.merkleLogEntry}
          element={<MerkleLogEntry roles={[ROLES.administrator, ROLES.user]} publicRoute={true} />}
        />
        <Route path={paths.archivist} element={<Archivist roles={[ROLES.administrator]} />} />
        <Route path={paths.archivistTab} element={<Archivist roles={[ROLES.administrator]} />} />
        <Route path={paths.accessPolicies} element={<AccessPolicies roles={[ROLES.administrator]} />} />
        <Route path={paths.accessPolicy()} element={<AccessPolicy roles={[ROLES.administrator]} />} />
        <Route
          path={paths.loggedOut}
          element={
            <Suspense fallback={<MenuPlaceholder />}>
              <LoggedOut />
            </Suspense>
          }
        />
        <Route path={paths.developerConsole} element={<DeveloperConsole roles={[ROLES.administrator, ROLES.user]} />} />
        <Route
          path={paths.documentSearch}
          element={
            <Suspense fallback={<DocumentSearchSuspense />}>
              <DocumentSearch />
            </Suspense>
          }
        />

        <Route path="*" element={<Navigate to="/auditor" />} />

        <Route path="/" element={<DefaultRoute roles={[ROLES.administrator]} />} />
      </Routes>

      <OnRouteChange
        action={(path: string, prevPath: string) => {
          // do nothing
        }}
      />
    </Suspense>
  )
}

export default AllRoutes
